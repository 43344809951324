import axios from 'axios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import { AuthToken } from '../modules/auth/authToken';
import config from 'src/config';
import { getLanguageCode } from 'src/i18n';
import { rectifyResponse } from 'src/modules/shared/axios/authAxios';

let cancelToken;

export const tenantSearch = async (text: string, collection: string, limit: number, offset: number) => {
  const tenantId = AuthCurrentTenant.get();
  const token = AuthToken.get();
  const baseURL = config.backendUrl;

  //Check if there are any previous pending requests
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }

  //Save the cancel token for the current request
  cancelToken = axios.CancelToken.source();

  try {
    const results = await axios.get(
      `${baseURL}/search/${tenantId}/list?field=${collection}&limit=${limit}&offset=${offset}&filter=${text}`,
      {
        cancelToken: cancelToken.token,
        headers: {
          Authorization: 'Bearer ' + token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Language': getLanguageCode(),
        },
      }, //Pass the cancel token to the current request
    );

    return rectifyResponse(results);
  } catch (error) {
    console.log(error);
  }
};
